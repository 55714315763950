
import * as React from 'react';
import { Button, Card, TextareaAutosize } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import app, { db, storage } from '../../firebase';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import './imageUpload.css';

interface IAdventureFormFields {
	author: string;
	title: string;
	textBody: string;
	imgSource: string;
	imgAltText: string;
}

interface IAdventureData extends IAdventureFormFields {
	realAuthor?:string;
	createdAt: Timestamp;
}

interface IAdventureFormProps {
	root?: string;
}

const auth = getAuth(app);

const AdventureForm = (props: IAdventureFormProps) => {
	const user = useSelector((state: RootState) => state.user.value);
	const [authUser, loading, error] = useAuthState(auth);

	const [image, setImage] = React.useState<File | null>();
	const [progress, setProgress] = React.useState(0);

	const defaultRoot = props.root ?? 'Adventures';

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if(!e) return;

		if (e?.target?.files) {
			const file = e?.target?.files[0];
			setImage(file ?? null);
		}
	};

	const formContext = useForm<IAdventureFormFields>({
		defaultValues: {
			imgAltText: '',
			author: '',
			title:'',
			textBody:'',
			imgSource:'',
		}
	});
	const { handleSubmit } = formContext;
	const onSubmit = handleSubmit((formData: IAdventureFormFields) => {
		const adventureData: IAdventureData = { ...formData, realAuthor: user?.name??'Unknown', createdAt: Timestamp.now(), };
		if (!image) return;

		const uploadTask = storage.ref(`images/${defaultRoot}/${image?.name}`).put(image);
		uploadTask.on(
			'state_changed',
			(snapshot) => {
				// Progress function
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgress(progress);
			},
			(err) => {
				// Error function
				console.log(err);
				alert(err.message);
			},
			() => {
				// Complete function
				storage
					.ref(`images/${defaultRoot}`)
					.child(image.name)
					.getDownloadURL()
					.then((url) => {
						adventureData.imgSource = url;
						db.collection(defaultRoot).doc().set(adventureData);
						setProgress(0);
						setImage(null);
					}).then(() => {
						alert('upload successful!');
						formContext.resetField('author');
						formContext.resetField('title');
						formContext.resetField('textBody');
						formContext.resetField('imgSource');
						formContext.resetField('imgAltText');	
					}).catch(() => {alert('Error uploading image.');});
			}
		);
	});
	return (
		<Card style={{display: 'flex', flexDirection: 'column'}}>
			<FormContainer
				formContext={formContext}
				defaultValues={{
					author: '',
					title:'',
					textBody:'',
					imgSource:'',
					imgAltText:''
				}}

				handleSubmit={onSubmit}
			>
				<div style={{height: '100%', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', padding: '5% 5%'}}>	
					<TextFieldElement name={'author'} label={'Author'} required /> <br />
					<TextFieldElement name={'title'} label={'Title'} required /> <br />
					<TextFieldElement name={'textBody'} label={'Story'} minRows={4} maxRows={7} multiline required />  <br />
					<TextFieldElement name={'imgAltText'} label={'Image Alt Text'} /> <br />
					{authUser && <div className="imageUpload">
						<div className="uploadCapBtn">
							<input className="uploadCap" type="file" onChange={handleChange} />
						</div>
						<progress className="progress" value={progress} max="100" />
					</div>}
					<Button variant='outlined' size='large' type={'submit'} color={'secondary'}>Submit</Button>
				</div>
			</FormContainer>
		</Card>);
};

export default AdventureForm;
