import { Box, Grid } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import firebase from 'firebase/compat';
import AdventureForm from '../components/Forms/AdventureForm';
import { RootState } from '../store';
import { db } from '../firebase';
import { Masonry } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import AdventureItem from '../components/ListItems/AdventureItem';
import StripeDivider from '../components/StripeDivider';



export function PeoplePage () {
	const [loading, setLoading] = React.useState(true);
	const [people, setPeople] = React.useState<firebase.firestore.DocumentData[]>([]);

	const user = useSelector((state: RootState) => state.user.value);

	const loadPeople = async () => {
		const people = await db.collection('People').get();
		const res: firebase.firestore.DocumentData[] = []; 
		people.docs.forEach((doc: { data: () => firebase.firestore.DocumentData; }) => {
			res.push(doc.data());
		});
		return res;
	};

	React.useEffect(() => {
		loadPeople().then((res) => {
			setPeople(res);
			setLoading(false);
		});
	},[]);
	
	return (
		<div className='Container' style={{width: '100vw', height: '100%', display:'flex', alignContent: 'center', alignItems: 'center', justifyContent:'center'}}>
			{isMobile ?
				<div style={{paddingBottom: '350px', height: '100vh'}}>
					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={0} >
							{ !loading && people.map((person, i) => {
								return(
									<Grid item md={6} key={i} style={{width: isMobile ? '100vw': '100%'}}>
										<AdventureItem {...person} ></AdventureItem>
										<StripeDivider />
									</Grid>);
							})
							}
						</Grid>
					</Box>
					{user.isAdmin && <div style={{height: '100vh'}}><AdventureForm root='People'/></div>}
				</div> :
				<div >
					<div className='Background-image' >
						{!loading && <Masonry columns={3} spacing={2}>
							{ !loading && people.map((person, i) => {
								return(
									<Grid item key={i}>
										<AdventureItem {...person} ></AdventureItem>
									</Grid>);
							})
							}
						</Masonry>}
					</div>
				</div>
			}
		</div>
	);
}
