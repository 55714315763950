import * as React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Profile from './components/Profile';
import { GamePage } from './pages/GamePage';
import { HomePage } from './pages/home/HomePage';
import { MerchPage } from './pages/MerchPage';
import { PeoplePage } from './pages/PeoplePage';
import app from './firebase';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { LoginPage } from './pages/LoginPage';
import { CreatorPage } from './pages/CreatorPage';

const auth = getAuth(app);

export function AppRoutes() {

	return (
		<Routes>
			<Route path="/" element={<HomePage />} /> {/* Home page */}
			<Route path="/login" element={<LoginPage />} /> {/* Login will probably make this a pop up so this is temp*/}
			<Route path="/people" element={<PeoplePage />} /> {/* Meet the team page */}
			<Route path="/merch" element={<MerchPage />} /> {/* Meet the team page */}
			<Route path="/game" element={<GamePage />} /> Meet the team page
			<Route path="/profile" element={<Profile />} /> {/* Your profile */}
			<Route path="/creator" element={<CreatorPage/>} />
			<Route
				// Need to make our own 404 component
				path="*"
				element={
					<main style={{ padding: '0rem' }}>
						<p>There&apos;s nothing here!</p>
					</main>
				}
			/>
		</Routes>
	);
}

function RequireAuth({ children }: { children: JSX.Element }) {
	const [hasUser, setUser] = React.useState(false) ;
	const location = useLocation();

	React.useEffect(() => {
		const [user, loading, error] = useAuthState(auth);

		setUser(!!user);
	},[]);

	if (!hasUser) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
}
