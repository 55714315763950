import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import lizard from '../../images/lizard.jpeg';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';
import { fontSize } from '@mui/system';
import { isMobile } from 'react-device-detect';

interface IMerchProps {
	productName?: string;
	description?: string;
	imgSource?: string
	imgAltText?: string
	price?: string
}

export default function MerchItem(props: IMerchProps) {

	const onClick = async () => {
		logEvent(analytics, 'someone clicked on order product: ' + props?.productName + ' cost: ' + props?.price);
		alert('Out of Stock Sorry');
		const shareData = {url: window.location.href, title: props?.productName, text: props?.description};
		await navigator.share(shareData);
	};

	const onLearnMore = () => {
		// window.location.href = 'https://en.wikipedia.org/wiki/Buy';
		logEvent(analytics, 'someone clicked on learn more for product: ' + props?.productName);
		window.location.href = 'https://en.wikipedia.org/wiki/Special:Random';
	};

	return (
		<Card sx={{ maxWidth: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
			<CardMedia
				style={{maxHeight: isMobile ? '80vh': '30vh', height: 'auto', width: '100%'}}
				component="img"
				height="220"
				image={props.imgSource ?? lizard}
				alt={props.imgAltText ?? 'Cheeky Lizard'}
			/>
			<CardContent style={{ flexGrow: 1, display: 'flex', flexDirection: 'column',}}>
				<Typography
					gutterBottom
					variant="h3"
					component="div"
					style={{ fontFamily: 'bradley', fontWeight: 'bold' }}
				>
					{props.productName ?? 'Lizard'}
				</Typography>
				<Typography style={{flexGrow: 1}} variant="body2" color="text.secondary">
					{props.description ??
						'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica'}
				</Typography>
			</CardContent>
			<CardActions
				style={{ justifyContent: 'flex-end', fontFamily: 'bradley' }}
			>
				<div style={{display: 'flex', justifyContent: 'flex-start', flexGrow: 1}}>
					<Button disabled color='primary' style={{ fontFamily: 'bradley', fontWeight: 'bold', color: '#07014a', fontSize: '1em' }}
						size="medium">{props?.price}</Button>
				</div>
				<Button color="secondary"
					style={{ fontFamily: 'bradley', fontWeight: 'bold' }}
					size="medium" onClick={onLearnMore}>Learn More</Button>
				<Button
					style={{ fontFamily: 'bradley', fontWeight: 'bold' }}
					variant="contained"
					size="medium"
					onClick={onClick}
				>
					Order
				</Button>
			</CardActions>
		</Card>
	);
}