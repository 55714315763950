import * as React from 'react';

const container = {
	display: 'flex',
	width: '100%',
	height: '5px',
};

const colorBar = {
	width: '20%',
	height: '5px'
};

export function StripeDivider() {
	const defaultColours = ['#140A86', '#3AB4F9', '#F0CD71', '#FF9D0A', '#057510'];
	return (
		<div style={container}>
			{defaultColours.map((colour, i) => {
				return (<div key={i} style={{...colorBar, backgroundColor: colour}}></div>);
			})}
			
		</div>
	);
}

export default StripeDivider;
