import { createSlice } from '@reduxjs/toolkit';

const initialState =  { name: '', email: '', isAdmin: false, isSignedIn: false };

const userSlice = createSlice({
	name: 'user',
	initialState: { value: initialState},
	reducers: {
		loginReducer: (state, action) => {
			state.value = action.payload;
		},

		logoutReducer: (state) => {
			state.value = initialState;
		}
	}
});

export const { loginReducer, logoutReducer } = userSlice.actions;

export default userSlice.reducer;