import { Box } from '@mui/material';
import * as React from 'react';
import { ChangeTheme } from '../components/ChangeTheme';
import axios from 'axios';
import Spotify from 'react-spotify-embed';

import Unity, { UnityContext } from 'react-unity-webgl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useEffect } from 'react';

const unityContext = new UnityContext({
	loaderUrl: 'gameFiles/Build.loader.js',
	dataUrl: 'gameFiles/Build.data',
	frameworkUrl: 'gameFiles/Build.framework.js',
	codeUrl: 'gameFiles/Build.wasm',
});


export function GamePage() {
	const user = useSelector((state: RootState) => state.user.value);
	const nav = useNavigate();
	const location = useLocation();

	const [token, setToken] = React.useState('');


	const client_id = 'd253ed7f4b1f4d7cae6b372a95bc0a38';
	const client_secret ='000f3871df0145819d677049a55ea881';


	// useEffect(() => {
	// 	const returnUrl = location.search;
	// 	const urlParams = new URLSearchParams(returnUrl);
	// 	const code = urlParams.get('code');
	// 	axios.get('https://api.spotify.com/v1/me', {
	// 		headers: {
	// 			'Authorization': 'Bearer ' + code,
	// 			'content-type': 'application/json'
	// 		},
	// 	});

	// 	//have code need to exchange for token

	// }, [location]);

	useEffect(() => {

		axios('https://accounts.spotify.com/api/token', {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': 'Basic ' + btoa(client_id + ':' + client_secret)
			},
			data: 'grant_type=client_credentials',
			method: 'POST'
		}).then(tokenResponse => {
			setToken(tokenResponse.data.access_token);

			axios('https://api.spotify.com/v1/browse/categories?locale=sv_US', {
				method: 'GET',
				headers: { 'Authorization': 'Bearer ' + tokenResponse.data.access_token }
			});
		});

	}, [client_id, client_secret]);

	const goToAuth = () => {
		const client_id = 'd253ed7f4b1f4d7cae6b372a95bc0a38';
		const url = 'https://accounts.spotify.com/authorize?client_id=' + client_id + '&response_type=code&redirect_uri=http://localhost:3000/game&show_dialog=true&scope=user-read-private';

		window.location.href = url;
	};

	return (
		<div style={{ width: '100vw' }}>
			{/* <Spotify wide link="https://open.spotify.com/track/4XCGfHpGVq8xw800o5cwWs?si=2ace54f8ec974c89" />
			<iframe style={{borderRadius: '12px'}} src="https://open.spotify.com/embed/track/4XCGfHpGVq8xw800o5cwWs?utm_source=generator" width="100%" height="380" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe> */}

			{/* Game Page */}
			{/* <Box sx={{ flexGrow: 1, padding: '0 5% 0 5%', height: '100vh' }}>
				<div style={{ width: '80%', height: '80%', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
					<Unity unityContext={unityContext} style={{ width: '390px', height: '800px' }} />
				</div>
			</Box> */}
		</div>
	);
}
