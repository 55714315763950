import { TabContext, TabPanel } from '@mui/lab';
import { Box, Card, FormControlLabel, FormGroup, Switch, Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdventureForm from '../components/Forms/AdventureForm';
import MerchForm from '../components/Forms/MerchForm';
import { RootState } from '../store';

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export function CreatorPage () {
	const user = useSelector((state: RootState) => state.user.value);
	const nav = useNavigate();
	const [checked, setValue] = React.useState(false);

	const handleChange = () => {
		setValue(!checked);
	};
	
	return (
		<div style={{ width: '100vw', paddingBottom: '100px'}}>
			<Box color={''} sx={{ flexGrow: 1, padding: '0 5% 0 5%' }}>
				<Card style={{display: 'flex', flexDirection: 'column'}}>
					<FormGroup>
						<FormControlLabel control={<Switch
							size='medium'
							color='secondary'
							checked={checked}
							onChange={handleChange}
							inputProps={{ 'aria-label': 'controlled' }}
						/>} label={checked ? 'Adventure Creator' : 'Merch Creator'}
						labelPlacement="top" />
					</FormGroup>
					
					{checked && <AdventureForm />}
					{!checked && <MerchForm/>}
				</Card>
			</Box>
		</div>
	);
}
