import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth, User } from 'firebase/auth';
import app, { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { loginReducer } from '../features/users';
import { useDispatch } from 'react-redux';
import AdventureForm from './Forms/AdventureForm';


const auth = getAuth(app);



export default function Profile() {
	const [user, loading, error] = useAuthState(auth);
	const [loadingProfile, setLoading] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const dispatch = useDispatch();
	const nav = useNavigate();
	// might go back to using redux at some point but for now just commenting out
	// const user = useSelector((state: RootState) => state.user.value);

	// redirects user to the login screen if then are logged out
	if (!user) {
		nav('/login');
	}

	const loadProfile = async (user: User) => {
		const res = await db.collection('Users').doc(user?.uid).get();
		const data = res.data();
		setIsAdmin(data?.isAdmin);
		setLoading(false);
		dispatch(loginReducer({ name: user.displayName, email: user.email, isAdmin: data?.isAdmin }));
		return Promise.resolve();
	};

	useEffect(() => {
		if (user)
			loadProfile(user).then(() => {
				if (!isAdmin && !loadingProfile) nav('/login');
			});
	});

	return (
		<div>{!loading && !loadingProfile  && <>
			<h1>Profile</h1>
			<p>Name: {user?.displayName}</p>
			<p>Email: {user?.email}</p>
			{!loadingProfile && <p>isAdmin: {isAdmin ? 'yes' : 'No'}</p>}
			{isAdmin && <AdventureForm/>}
		</>}
		</div>
	);
}

