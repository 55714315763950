import * as React from 'react';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import { getThemeByName } from './features/theme';
import { NavButtons } from './components/NavButtons';
import { isMobile } from 'react-device-detect';
import { AppRoutes } from './AppRoutes';
import { MobileNavBar } from './components/MobileNavBar';

function App() {
	const themeState = useSelector((state: RootState) => state.theme.value);
	const theme = getThemeByName(themeState);


	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Router>
				<div className="App" style={{width:'100vw'}}>
					{isMobile ?
						<div >
							<AppRoutes />
							<MobileNavBar />
						</div> :
						<div >
							<div className='Nav-container'>
								<NavButtons />
								<AppRoutes />
							</div>
						</div>
					}
				</div>
			</Router>
		</ThemeProvider>
	);
}

export default App;
