import { auth } from '../firebase';

/// AUTH FUNCTIONS

export const createAccount = (email: string, password: string) => {
	return auth.createUserWithEmailAndPassword(
		email,
		password
	);
};

export const login = async (email: string, password: string) => {
	return auth.signInWithEmailAndPassword(
		email,
		password
	);
};