import { Button, Stack, BottomNavigation } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import * as React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { logoutReducer } from '../features/users';
import app from '../firebase';
import pizzaImage from '../images/poven-logo.jpg';
import { backgroundNavy } from './constants';
import navOven from '../images/navOven.png';

const auth = getAuth(app);

const navButton = {
	width: '30px',
	fontSize: 'calc(5px + 2vmin)',
	fontFamily: 'bradley',
	fontWeight: 'bold',
};

const navStyle = {
	bottom: '-8px',
	left: 0,
	width: '100vw',
	display: 'flex',
	height: '80px',
	backgroundColor: backgroundNavy,
	paddingBottom: '10px',
};

export function MobileNavBar() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [user, loading, error] = useAuthState(auth);

	const handleClick = (path: string) => {
		navigate(path);
	};

	const handleSignOut = () => {
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				dispatch(logoutReducer());
			})
			.catch((error) => {
				// An error happened.
			});
	};

	return (
		<nav >
			<Outlet />
			<BottomNavigation
				style={{ position: 'fixed', flexDirection: 'column', ...navStyle }}
			>
				{/* <StripeDivider/> */}
				<img src={navOven} style={{zIndex:-1, position:'absolute', width: '100vw', bottom: '30px', pointerEvents: 'none'}} />
				<div style={{zIndex:-1, position:'absolute', width: '100vw', height: '30px', bottom: '0', backgroundColor:'#000485'}}></div>
				<Stack
					width="100%"
					direction="row"
					justifyContent="space-evenly"
					alignItems="center"
					aria-label="large button group"
				>
					<Button
						style={navButton}
						variant="text"
						onClick={() => handleClick('/game')}
					>
            P.Game
					</Button>
					<Button
						style={navButton}
						variant="text"
						onClick={() => handleClick('/merch')}
					>
            P.Merch
					</Button>
					<Button
						style={navButton}
						color="secondary"
						onClick={() => handleClick('/')}
					>
						<img src={pizzaImage} height={48}></img>
					</Button>
					<Button
						style={navButton}
						variant="text"
						onClick={() => handleClick('/people')}
					>
            P.People
					</Button>
					{!user ? (
						<Button
							style={navButton}
							variant="text"
							onClick={() => handleClick('/login')}
						>
              Login
						</Button>
					) : (
						<Button
							style={navButton}
							variant="text"
							onClick={() => handleSignOut()}
						>
              Logout
						</Button>
					)}
				</Stack>
			</BottomNavigation>
		</nav>
	);
}
