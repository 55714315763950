import { Button, Grid, Toolbar, Stack } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import * as React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { logoutReducer } from '../features/users';
import app from '../firebase';
import pizzaImage from '../images/poven-logo.jpg';

const auth = getAuth(app);

const navButton = {
	width: '160px',
	fontSize: 'calc(10px + 2vmin)',
	fontFamily: 'bradley',
	font: 'black',
	fontWeight: 'bold'
};

export function NavButtons() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [user, loading, error] = useAuthState(auth);

	const handleClick = (path: string) => {
		navigate(path);
	};

	const handleSignOut = () => {
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				dispatch(logoutReducer());
			})
			.catch((error) => {
				// An error happened.
			});
	};

	return (
		<nav>
			<Grid>
				<Toolbar style={{ width: '100%' }}>
					<Stack
						width="100%"
						direction="row"
						justifyContent="space-evenly"
						alignItems="center"
						aria-label="large button group"
					>
						<Button
							style={navButton}
							variant="text"
							onClick={() => handleClick('/game')}
						>
              P.Game
						</Button>
						<Button
							style={navButton}
							variant="text"
							onClick={() => handleClick('/merch')}
						>
              P.Merch
						</Button>
						<Button
							style={navButton}
							variant="text"
							onClick={() => handleClick('/')}
						>
							<img src={pizzaImage} height={64}></img>
						</Button>
						<Button
							style={navButton}
							variant="text"
							onClick={() => handleClick('/people')}
						>
              P.People
						</Button>
						{!user ? (
							<Button
								style={navButton}
								variant="text"
								onClick={() => handleClick('/login')}
							>
                Login
							</Button>
						) : (
							<Button
								style={navButton}
								variant="contained"
								onClick={() => handleSignOut()}
							>
                Logout
							</Button>
						)}
					</Stack>
				</Toolbar>
			</Grid>

			<Outlet />
		</nav>
	);
}
