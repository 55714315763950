import { Box, Card } from '@mui/material';
import * as React from 'react';
import Login from '../components/Login';
import { isMobile } from 'react-device-detect';


export function LoginPage () {
	return (
		<div style={{ width: '100vw', height: '100%' }}>
			<Box sx={{ flexGrow: 1, padding: '0 5% 0 5%' }} style={{display: 'flex', justifyContent: 'center'}}>
				<Card style={{width: isMobile ? '90vw' : '50vw', backgroundColor: '#96c7ef'}} raised={true}>
					<Login />
				</Card>
			</Box>
		</div>
	);
}
