import { createTheme } from '@mui/material';
import { blueGrey, green, grey } from '@mui/material/colors';
import { Theme } from '@mui/system/createTheme';
import { createSlice } from '@reduxjs/toolkit';

export const lightTheme = createTheme({
	palette: {
		primary: {
			main: '#ffffff',
			contrastText: grey[900],
		},
		secondary: {
			main: '#42a5f5',
			contrastText: grey[900],
		},
	},
	
});
const darkTheme = createTheme({
	palette: {
		primary: {
			main: grey[400],
			contrastText: '#fff'
		},
		secondary: {
			main: blueGrey[900]
		}
	}
});

export function getThemeByName(theme: string): Theme {
	return themeMap[theme];
}
  
const themeMap: { [key: string]: Theme } = {
	lightTheme,
	darkTheme
};

const initialState =  'lightTheme';


const themeSlice = createSlice({
	name: 'theme',
	initialState: { value: initialState},
	reducers: {
		updateTheme: (state, action) => {
			state.value = action.payload;
		},
		resetTheme: (state) => {
			state.value = initialState;
		}
	}
});

export const { updateTheme } = themeSlice.actions;

export default themeSlice.reducer;