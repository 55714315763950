import * as React from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import lizard from '../../images/lizard.jpeg';
import { Paper } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';
import { Timestamp } from 'firebase/firestore';

export interface IAdventureProps {
    title?: string;
    textBody?: string;
    imgSource?: string;
    imgAltText?: string;
    author?: string;
    createdAt?: Timestamp;
}

export default function AdventureItem(props: IAdventureProps) {

	const handleShare = async () => {
		logEvent(analytics, 'someone clicked share: ' + props?.title + ' by ' + props?.author);
		const shareData = {url: window.location.href, title: props?.title, text: props?.author};
		await navigator.share(shareData);
	};

	return (
		<Paper sx={{ maxWidth: '100%',  widht: isMobile ? '100vw' : '100%' }}>
			<CardMedia
				style={{maxHeight: '80vh', height: 'auto', width: '100%'}}
				component="img"
				image={props.imgSource ?? lizard}
				alt={props.imgAltText ?? 'Cheeky Lizard'}
			/>
			<CardContent>
				<Typography
					gutterBottom
					variant="h3"
					component="div"
					style={{ fontFamily: 'bradley', fontWeight: 'bold' }}
				>
					{props.title ?? 'Lizard'}
				</Typography>
				<Typography
					gutterBottom
					variant="h5"
					component="div"
					style={{ fontFamily: 'bradley', fontWeight: 'bold' }}
				>
					{props.author ?? 'Lizard'}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{props.textBody ??
                        'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica'}
				</Typography>

				<div style={{display: 'flex', marginTop: '16px'}}>
					<Typography variant="caption" color="text.secondary" alignSelf="flex-start">
						{props.createdAt?.toDate().toDateString() ??''}
					</Typography>
				</div>
			</CardContent>

			<CardActions style={{ justifyContent: 'flex-end', fontFamily: 'bradley' }}>
				<Button
					color="secondary"
					style={{ fontFamily: 'bradley', fontWeight: 'bold' }}
					size="medium"
					onClick={handleShare}>
                    Share
				</Button>
			</CardActions>
		</Paper>
	);
}