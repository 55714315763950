import * as React from 'react';
import { useDispatch } from 'react-redux';
import { loginReducer } from '../features/users';
import {
	getAuth,
	GoogleAuthProvider,
	signInWithPopup,
} from 'firebase/auth';
import { useState } from 'react';
import { login, createAccount } from '../providers/authHelper';
import app, { db } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Button, Input, InputAdornment, TextField, Typography } from '@mui/material';
import { AccountCircle, LockRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const auth = getAuth(app);

export default function Login() {
	// The User login playground
	const dispatch = useDispatch();
	const [email, updateEmail] = useState('');
	const [pw, updatePw] = useState('');
	const [user, loading, error] = useAuthState(auth);
	const nav = useNavigate();

	/**
   * Create User with email and password
   */
	const handleCreateUser = () =>
		createAccount(email, pw)
			.then((userCredential) => {
				if (userCredential?.user === null) return;
				// Signed in
				const user = userCredential.user;
				const userRecord: IUserRecord = {
					uid: user.uid,
					displayName: user.displayName,
					email: user.email,
					emailVerified: user.emailVerified,
					phoneNumber: user.phoneNumber,
					photoURL: user.photoURL,
				};
				alert('User Created');
				createProfile(userRecord);
				dispatch(loginReducer({ name: user.displayName, email: user.email, isSignedIn: true }));
				nav('/');
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				if (errorCode == 'auth/weak-password') {
					alert('The password is too weak.');
				} else {
					alert(errorMessage);
				}
				console.log(error);
			});

	/**
   * Email and Password sign in
   */
	const handleSignIn = () =>
		login(email, pw)
			.then( async (userCredential) => {
				if (userCredential?.user === null) return;
				// Signed in
				const user = userCredential.user;
				const res = await getProfile(user.uid);
				dispatch(loginReducer({ name: user.displayName, email: user.email, isAdmin: res?.isAdmin, isSignedIn: true  }));
				nav('/');
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				if (errorCode == 'auth/weak-password') {
					alert('The password is too weak.');
				} else {
					alert(errorMessage);
				}
				console.log(error);
			});

	// Needed for the good pop-up sign in
	const provider = new GoogleAuthProvider();

	/**
   * Google Pop-up create user / sign in
   */
	const popup = () =>
		signInWithPopup(auth, provider)
			.then( async (result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const token = credential?.accessToken;
				// The signed-in user info.
				const user = result.user;
				const userRecord: IUserRecord = {
					displayName: user.displayName,
					uid: user.uid,
					email: user.email,
					phoneNumber: user.phoneNumber,
					photoURL: user.photoURL,
					emailVerified: user.emailVerified
				};
				const res = await getProfile(user.uid);
				dispatch(
					loginReducer({ name: user.displayName, email: user.email, isAdmin: res?.isAdmin, isSignedIn: true })
				);
				
				checkIfProfileExists(user.uid).then((exists) => {if(!exists)createProfile(userRecord);});
				nav('/');
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
				// The AuthCredential type that was used.
				const credential = GoogleAuthProvider.credentialFromError(error);
				// ...
			});

	const params = {inputProps: {autoComplete: 'off'}};
	const inputProps = params.inputProps;

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				padding: 20
			}}
		>
			<Typography
				gutterBottom
				variant="h3"
				component="div"
				style={{ fontFamily: 'bradley', fontWeight: 'bold' }}
			>
				Login
			</Typography>
			<TextField
				label="Email"
				margin="dense"
				variant="outlined"
				type="email"
				{...params}
				InputProps={{
					...inputProps,
					startAdornment: (
						<InputAdornment position="start" style={{marginRight: '20px'}}>
							<AccountCircle />
						</InputAdornment>
					),
				}}
				
				required
				value={email}
				onChange={(event) => updateEmail(event.target.value)}
			/>
			<div style={{ height: 20 }} />
			<TextField
				label="Password"
				margin="normal"
				variant="outlined"
				type="password"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start" style={{marginRight: '20px'}}>
							<LockRounded />
						</InputAdornment>
					),
				}}
				required
				value={pw}
				onChange={(event) => updatePw(event.target.value)}
			/>
			<div style={{ height: 20 }} />
			<Button size='large' color="primary" variant="outlined" onClick={handleSignIn}>
          Login
			</Button>
			<div style={{ height: 20 }} />
			<Button size='large' variant="outlined" onClick={popup}>Login with Google</Button>
			<div style={{ height: 20 }} />
			<Button color="secondary" onClick={handleCreateUser}>
          Create New User
			</Button>
		</div>
	);
}

interface IUserRecord {
  uid: string;
  displayName: string | null;
  email: string | null;
  emailVerified: boolean;
  phoneNumber: string | null;
  photoURL: string | null;
}

/**
 * Creates a document with ID -> uid in the `Users` collection.
 *
 * @param {IUserRecord} userRecord Contains the auth, uid and displayName info etc.
 */
const createProfile = (userRecord: IUserRecord) => {
	return db
		.collection('Users')
		.doc(userRecord.uid)
		.set(userRecord)
		.catch(console.error);
};

const checkIfProfileExists = async (uid: string) => {
	const res = await db.collection('Users').doc(uid).get();
	return res.exists;
};

const getProfile = async (uid: string) => {
	const res = await db.collection('Users').doc(uid).get();
	return res.data();
};