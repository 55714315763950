import { Box, Grid, Paper, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import MerchItem from '../components/ListItems/MerchItem';
import { db } from '../firebase';
import greenHat from '../images/greenHat.png';
import lbHat from '../images/lightBlueHat.png';
import orgHat from '../images/orangeHat.png';
import firebase from 'firebase/compat';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store';
import { isMobile } from 'react-device-detect';


// temp stand in merch item
const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const loadProducts = async () => {
	const products = await db.collection('Products').get();
	const res: firebase.firestore.DocumentData[] = []; 
	products.docs.forEach((doc) => {
		res.push(doc.data());
	});
	return res;
};

export function MerchPage() {
	const [loading, setLoading] = useState(true);
	const [products, setProducts] = useState<firebase.firestore.DocumentData[]>([]);
	const user = useSelector((state: RootState) => state.user.value);
	const nav = useNavigate();


	React.useEffect(() => {
		loadProducts().then((res) => {
			setProducts(res);
			setLoading(false);
		});
	});

	return (
		<div>
			<Typography
				gutterBottom
				variant="h3"
				component="div"
				style={{ fontFamily: 'bradley', fontWeight: 'bold', paddingTop: '15px', width: '100vw' }}
			>
				The Merchandise
			</Typography>
			<Box sx={{ flexGrow: 1, padding: '0 5% 0 5%', paddingBottom: '100px' }}>
				<Grid container spacing={2} columns={isMobile ? 1 : 4}>
					{ !loading && products.map((product, i) => {
						return(
							<Grid item md={isMobile ? 1 : 2} key={i} style={{ display: 'flex', flexDirection: 'column', width: isMobile? '100vw': '45vw'}}>
								<MerchItem {...product} ></MerchItem>
							</Grid>);
					})
					}
				</Grid>
			</Box>
		</div>
	);
}
