import { Box, Button, Grid } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import * as React from 'react';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import AdventureItem from '../../components/ListItems/AdventureItem';
import StripeDivider from '../../components/StripeDivider';
import homeOven from '../../images/background-image-mobile.png';
import bucketChild from '../../images/bucketChild.png';
import bucketMorm from '../../images/bucketMorm.png';
import hett from '../../images/hett.png';
import alps from '../../images/palps.png';
import './homeStyles.css';
import firebase from 'firebase/compat';
import { db } from '../../firebase';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { faker } from '@faker-js/faker';
import { IAdventureProps } from '../../components/ListItems/AdventureItem';
import { useNavigate } from 'react-router-dom';
import AdventureForm from '../../components/Forms/AdventureForm';


const creatAdventure = () => {

	const adventure: IAdventureProps = {
		title: `The ${faker.word.adjective()} ${faker.animal.type()}`,
		author: `${faker.name.firstName()} ${faker.name.lastName()} ${faker.name.suffix()}`,
		textBody: faker.lorem.paragraphs( Math.floor(Math.random() * (10 - 1 + 1) + 1)),
		imgSource: faker.image.nature(),
		imgAltText: faker.lorem.sentence()
	};
	db.collection('Adventures').doc().set(adventure);
};


const loadAdventures = async () => {
	const products = await db.collection('Adventures').orderBy('createdAt', 'desc').get();
	const res: firebase.firestore.DocumentData[] = []; 
	products.docs.forEach((doc) => {
		res.push(doc.data());
	});
	return res;
};

export function HomePage() {
	const [loading, setLoading] = React.useState(true);
	const [adventures, setAdventures] = React.useState<firebase.firestore.DocumentData[]>([]);
	const user = useSelector((state: RootState) => state.user.value);
	const nav = useNavigate();

	React.useEffect(() => {
		loadAdventures().then((res) => {
			setAdventures(res);
			setLoading(false);
		});
	},[]);

	
	const goToCreator = () => {
		nav('/creator');
	};



	const imgStyle = {
		display: 'block',
		maxWidth: '100%',
		width: '100vw'
	};

	const endImageRef = React.useRef<HTMLImageElement>(null);

	const scrollToBottom = () => {
		if (endImageRef !== null)
			endImageRef.current?.scrollIntoView({ behavior: 'auto',  block: 'end',inline: 'end' });
	};

	useEffect(() => {
		scrollToBottom();
	}, []);

	return (
		<div className='Container'>
			{isMobile ?
				<div style={{paddingBottom: '50px'}}>
					<img ref={endImageRef} src={homeOven} style={imgStyle}/>
					{user.isAdmin && <div style={{width: '100%', height: '80px', backgroundColor: '#000000', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems:'center'}}><Button className='creator-button' onClick={goToCreator}>Take me to The Oven</Button></div>}
					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={0} >
							<StripeDivider />
							{ !loading && adventures.map((adventure, i) => {
								return(
									<Grid item md={6} key={i} style={{width: isMobile ? '100vw': '100%'}}>
										<AdventureItem {...adventure} ></AdventureItem>
										<StripeDivider />
									</Grid>);
							})
							}
						</Grid>
						{user.isSignedIn && <div style={{paddingBottom: '100px'}}><AdventureForm/></div>}
					</Box>
				</div> :
				<div >
					{user.isAdmin && <Button className='creator-button' onClick={goToCreator}>Creator Page</Button>}
					<div className='Background-image'>
						{!loading && <Masonry columns={3} spacing={2}>
							{ !loading && adventures.map((adventure, i) => {
								return(
									<Grid item key={i}>
										<AdventureItem {...adventure} ></AdventureItem>
									</Grid>);
							})
							}
						</Masonry>}
					</div>
				</div>
			}

			

		</div>
	);
}
