
import * as React from 'react';
import { Button, Card } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import app, { db, storage } from '../../firebase';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import './imageUpload.css';
import { type } from '@testing-library/user-event/dist/type';

interface IMerchFormFields {
	productName: string;
	description: string;
	imgSource: string;
	imgAltText?: string;
    price: string;
}

interface IMerchData extends IMerchFormFields {
	creator?:string;
	creationDate: Timestamp;
}

const auth = getAuth(app);

const MerchForm = () => {
	const user = useSelector((state: RootState) => state.user.value);
	const [authUser, loading, error] = useAuthState(auth);

	// set image type
	const [image, setImage] = React.useState<File | null>();
	const [progress, setProgress] = React.useState(0);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if(!e) return;

		if (e?.target?.files) {
			const file = e?.target?.files[0];
			setImage(file ?? null);
		}
	};

	const formContext = useForm<IMerchFormFields>({
		defaultValues: {
			imgAltText: '',
			productName:'',
			description:'',
			imgSource:'',
			price: ''
		}
	});
	const { handleSubmit } = formContext;
	const onSubmit = handleSubmit((formData: IMerchFormFields) => {
		const merchData: IMerchData = { ...formData, creator: user?.name??'Unknown', creationDate: Timestamp.now() };
		if (!image) return;

		const uploadTask = storage.ref(`images/merch/${image?.name}`).put(image);
		uploadTask.on(
			'state_changed',
			(snapshot) => {
				// Progress function
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgress(progress);
			},
			(err) => {
				// Error function
				console.log(err);
				alert(err.message);
			},
			() => {
				// Complete function
				storage
					.ref('images/merch')
					.child(image.name)
					.getDownloadURL()
					.then((url) => {
						merchData.imgSource = url;
						db.collection('Products').doc().set(merchData);
						setProgress(0);
						setImage(null);
					})
					.then(() => {
						alert('upload successful!');
						formContext.resetField('productName');
						formContext.resetField('price');
						formContext.resetField('description');
						formContext.resetField('imgSource');
						formContext.resetField('imgAltText');	
					})
					.catch((e) => {
						alert('Error uploading image.');
					});
			}
		);
	});

	return (
		<Card style={{display: 'flex', flexDirection: 'column'}}>
			<FormContainer
				formContext={formContext}
				defaultValues={{
					imgAltText: '',
					productName:'',
					description:'',
					imgSource:'',
					price: ''
				}}

				handleSubmit={onSubmit}
			>
				<div style={{height: '100%', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', padding: '5% 5%'}}>	
					<TextFieldElement name={'productName'} label={'Product Name'} required /> <br />
					<TextFieldElement name={'description'} label={'Description'} minRows={4} maxRows={7} multiline required />  <br />
					<TextFieldElement name={'price'} label={'Price'} /> <br />
					<TextFieldElement name={'imgAltText'} label={'Image Alt Text'} /> <br />
					{authUser && <div className="imageUpload">
						<progress className="progress" value={progress} max="100" />
						<div className="uploadCapBtn">
							<input className="uploadCap" type="file" onChange={handleChange} />
						</div>
					</div>}
					<Button variant='outlined' size='large' type={'submit'} color={'secondary'}>Submit</Button>
				</div>
			</FormContainer>
		</Card>);
};

export default MerchForm;
